<template>
    <div>
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/routes" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Menu tras</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                     <router-link to="list" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Lista tras</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Archiwum tras</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/dashboard/routes/list" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

        <!-- <label for="search" class="block text-sm font-medium text-gray-700 mt-4">Search</label> -->

        <!-- SEARCHER --> 
        <div class="min-w-0 flex-1 xl:col-span-6 mt-10">
            <div  :class="{'flex flex-col sm:flex-row items-center justify-between': true}">
                <div :class="{'w-full sm:flex': true, 'sm:justify-end': !showStatusFilters,  'justify-between flex-col space-y-4 lg:space-y-0 lg:flex-row': this.showStatusFilters}">
                    
                    <Transition name="fade">
                    <div v-if="showStatusFilters === true" class="flex flex-col justify-between gap-4 select-none">
                        <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                            <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">

                                <div class="flex items-center">
                                    <div :class="{'relative w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null}">
                                        <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data wyjazdu</label>
                                        <input :disabled="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" type="date" @change="pickDateOfDelivery($event)" v-model="pickedDateOfDelivery" ref="pickedDeliveryDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                                    </div>
                                    <Transition name="fade2">
                                    <div v-if="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" class="ml-2 mr-2">
                                        <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                                    </div>
                                    </Transition>
                                </div>
                                <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                                    <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                        <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie</label>
                                        <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                            <option value="creationDesc">Utworzono: Od najnowszych</option>
                                            <option value="creationAsc">Utworzono: Od najstarszych</option>
                                            <option value="departureDateAsc">Data wyjazdu: Od najwcześniejszych</option>
                                            <option value="departureDateDesc">Data wyjazdu: Od najpóźniejszych</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                                    <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                        <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Kraj</label>
                                        <select v-model="pickedCountry" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                            <option value="all">Wszystkie</option>
                                            <option v-for="(country, index) in countries" :key="index" :value="country.name">{{country.displayName}}</option>
                                        </select>
                                    </div>
                                </div>
                                <button @click="this.getRoutes()" type="button" class="transition-all duration-300 rounded-md bg-indigo-100 px-3 py-3 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200">
                                    Zastosuj
                                </button>
                            </div>
                        </div>
                    </div>
                    </Transition>
                    
                    <!-- <Transition name="fade">
                    <div v-if="showStatusFilters === true" class="mr-4 flex flex-col lg:flex-row w-full justify-between lg:items-center lg:space-x-3 space-y-4 lg:space-y-0 select-none">
                        <div class="flex flex-col lg:flex-row lg:space-x-3  space-y-4 lg:space-y-0">
                            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie</label>
                                <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                    <option value="creationAsc">Data powrotu: Od najstarszych</option>
                                    <option value="creationDesc">Data powrotu: Od najnowszych</option>
                                </select>
                            </div>
                        <button type="button" @click="this.getRoutes()" class="transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Zastosuj</button>
                        </div>
                    </div>
                    </Transition>  -->
                    
                    <div v-if="searcher.found.length === 0" :class="{'flex justify-between items-end lg:items-center lg:justify-start transition-all duration-1000 w-auto': true, 'min-w-filters': showStatusFilters}">
                        <div @click="showFilters()" class="mr-4 cursor-pointer flex justify-center items-center flex-col select-none">
                            <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                            <span class="text-xs font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                        </div>
                        <div v-if="showStatusFilters" :class="{'flex lg:hidden items-center justify-center': true}">                        
                        </div>
                        <div class="hidden sm:flex">
                            <div v-if="showStatusFilters === false">
                                <div :class="{'flex items-center justify-center gap-2': true, 'hidden lg:flex': this.showStatusFilters, 'flex': !this.showStatusFilters}">
                                    <router-link to="list" type="button" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 hover:bg-blue-700  px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                    <ArrowLeftIcon class="h-5 w-5" aria-hidden="true" />Wróć
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div :class="{'flex items-center justify-center': true, 'hidden lg:flex': this.showStatusFilters, 'flex': !this.showStatusFilters}" />
            </div>
        </div>
        <TableRoutesList  :manageRoute="true" :routes="routes" />
        <div class="mt-3 flex items-center justify-center" v-if="!queryLimitReached && this.orders">
            <button v-if="!loadingTable" type="button" @click.prevent="loadMoreProducts" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
        </div> 
        <EmptyState class="mt-2 mb-2" v-if="routes.length === 0" />
    </div>
</template>
<script>
import { HomeIcon } from '@heroicons/vue/solid'
import {db} from "@/firebase/gfbconf.js";
import { SearchIcon, PlusSmIcon, XIcon , FilterIcon, ArrowLeftIcon } from '@heroicons/vue/outline';
import EmptyState from '../components/EmptyState.vue'
import axios from 'axios';
import moment from 'moment';
import TableRoutesList from '../components/TableRoutesList.vue'


export default {
    data() {
        return {
                countries: [
                {
                    displayName: 'Polska',
                    name: 'Poland',
                    code: 'PL',
                    flagUrl: 'https://flagcdn.com/w320/pl.png',
                },
                {
                    displayName: 'Niemcy',
                    name: 'Germany',
                    code: 'DE',
                    flagUrl: 'https://flagcdn.com/w320/de.png',
                },
                {
                    displayName: 'Francja',
                    name: 'France',
                    code: 'FR',
                    flagUrl: 'https://flagcdn.com/w320/fr.png',
                },
                {
                    displayName: 'Luksemburg',
                    name: 'Luxembourg',
                    code: 'LU',
                    flagUrl: 'https://flagcdn.com/w320/lu.png',
                },
                {
                    displayName: 'Włochy',
                    name: 'Italy',
                    code: 'IT',
                    flagUrl: 'https://flagcdn.com/w320/it.png',
                },
                {
                    displayName: 'Holandia',
                    name: 'Netherlands',
                    code: 'NL',
                    flagUrl: 'https://flagcdn.com/w320/nl.png',
                },
                {
                    displayName: 'Belgia',
                    name: 'Belgium',
                    code: 'BE',
                    flagUrl: 'https://flagcdn.com/w320/be.png',
                },
                {
                    displayName: 'Austria',
                    name: 'Austria',
                    code: 'AT',
                    flagUrl: 'https://flagcdn.com/w320/at.png',
                },
            ],
            pickedDateOfDelivery: null,
            pickedDateOfDeliveryUnix: null,
            pickedCountry: 'all',
            currentDate:'',
            sortName:'creationDesc',
            sortType: {
                    order: "desc",
                    type: "date.end"
                },
            showStatusFilters: false,
            modal: {
                open: false
            },
            moment: moment,
            routes:[],
            orders: [],
            searcher: {
                found: [],
                locked: false,
                phrase: "",
                lastCall: null
            },
            vehicles: {},
            queryLimit: 20,
            // dbListener: null,
            queryLimitReached: false,
            loadingTable: true,
        }
    },
    components: {
        EmptyState, TableRoutesList, HomeIcon, SearchIcon, PlusSmIcon, XIcon,FilterIcon,ArrowLeftIcon
    },
    methods: {
        async getVehicles(){
            let vehicleData = await db.collection('Vehicles').get()
            for (let i = 0; i < vehicleData.docs.length; i++) {
                const vehicle = vehicleData.docs[i];
                const vehicleToPush = vehicle.data()
                this.vehicles[vehicleToPush.id] = vehicleToPush
            }
        },
        confirmSort(){
            // this.dbListener();
            this.getRoutes()
        },
        showFilters()
        {
            this.showStatusFilters = !this.showStatusFilters
            if(!this.showStatusFilters)
            {
                this.pickedCountry = 'all';
                this.sortName = "creationDesc";
                this.sortType = {
                    order: "desc",
                    type: "meta.createdDate"
                },
                this.pickedDateOfDelivery = null;
                this.pickedDateOfDeliveryUnix = null;
                this.getRoutes()
            }
        },
        changeSort()
        {
            if(this.sortName === "creationAsc"){
                this.sortType = {
                    order: "asc",
                    type: "meta.createdDate"
                }
            }
            if(this.sortName === "creationDesc"){
                this.sortType = {
                    order: "desc",
                    type: "meta.createdDate"
                }
            }
            if(this.sortName === "returnDateAsc"){
                this.sortType = {
                    order: "asc",
                    type: "date.end"
                }
            }
            if(this.sortName === "returnDateDesc"){
                this.sortType = {
                    order: "desc",
                    type: "date.end"
                }
            }
            if(this.sortName === "departureDateAsc"){
                this.sortType = {
                    order: "asc",
                    type: "date.start"
                }
            }
            if(this.sortName === "departureDateDesc"){
                this.sortType = {
                    order: "desc",
                    type: "date.start"
                }
            }
        },
        resetDateOfCreate(){
            this.pickedDateOfDelivery = null;
            this.pickedDateOfDeliveryUnix = null
        },
        pickDateOfDelivery(event){
            let mDate = moment(event.target.value)
            if(!mDate.isValid())
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Popraw datę!",
                    subheader: `Wykryto, że data jest niepoprawna.`,
                    success: false
                });
                this.pickedDateOfDelivery = null;
                this.pickedDateOfDeliveryUnix = null
            }
            else
            {
                this.pickedDateOfDeliveryUnix = mDate.unix()
            }
        },
        findStatus(statusId)
        {
            return this.statuses.find(el => el.id === statusId)
        },
        async getRoutes()
        {
            this.loadingTable = true
            let query = db.collection("Routes").where("statusId", "==", 3)
            
            if(this.pickedCountry !== 'all')
        
            {
                query = query.where('country.name', "==", this.pickedCountry)
            }
            
            if(this.pickedDateOfDeliveryUnix !== null){
                this.pickedDateOfDeliveryUnix = parseInt(this.pickedDateOfDeliveryUnix)
                let startOfDayDelivery = this.pickedDateOfDeliveryUnix
                query = query.where('date.start', "==", moment.unix(startOfDayDelivery).toDate())
            }
            
            query = query.orderBy("date.end", `${this.sortType.order}`)
            let routesData = await query.limit(this.queryLimit).get()
            //clear routes before every change
            this.routes = []
            if(routesData.docs.length<this.queryLimit) {
                this.queryLimitReached = true;
            }
            else
            {
                this.queryLimitReached = false;
            }
            for (let i = 0; i < routesData.docs.length; i++) {
                const data = routesData.docs[i].data()
                data.vehicle = this.vehicles[data.vehicle.id]
                this.routes.push(data)
            }
            this.loadingTable = false
        },
        selectOrders(country, codes)
        {
            const countryOrders = this.orders.filter(order => order.delivery.country.code === country);
            const groupOrders = []

            switch (country) {
                case 'PL':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'DE':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'FR':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'IT':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'NL':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'BE':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                case 'AT':
                    countryOrders.forEach(order => {
                        const region = order.delivery.postCode.slice(0, 2)
                        if(codes.includes(region)){
                            groupOrders.push(order)
                        }
                    })
                    break;
                default:
                    console.log(`Country ${country} not supported`);
                    break;
            }

            return groupOrders
        },
        resetSearch()
        {  
            this.products = [];
            this.searcher.found = [];
            this.searcher.lastCall = null;
            this.searcher.phrase = "";
            this.getRoutes()
        },
        continueListening()
        {
            this.queryLimit = this.queryLimit + 10;
            this.getRoutes();
        },
        loadMoreProducts()
        {
            // this.dbListener();
            this.continueListening();      
        },
        copyToClipboard(id)
        {
            navigator.clipboard.writeText(id).then(()=>
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Skopiowano do schowka!",
                    subheader: ``,
                    success: true
                }); 
            })
        },
    },
    async created()
    {   
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.routes) {
            this.$router.push("/hub")
        }
        await this.getVehicles()
        this.getRoutes()
    },
 }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
  transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
  opacity: 0;
  }
</style>