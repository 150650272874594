<template>
    <div>
        <div class="hidden md:flex justify-between items-center mb-4">
            <nav class="hidden md:flex h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex flex-row justify-between space-x-4 rounded-md bg-white px-6 shadow">
                    <li class="flex">
                        <div class="flex items-center">
                        <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span class="sr-only">Home</span>
                        </router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <router-link to="/dashboard/routes" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Menu tras</router-link>
                        </div>
                    </li>
                    <li class="flex">
                        <div class="flex items-center">
                        <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                        </svg>
                        <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Lista tras</div>
                        </div>
                    </li>
                </ol>
            </nav>
            <div v-if="!loading">
                <span class="text-gray-500 hover:text-gray-400 select-none transition-all duration-300 font-semibold text-md">({{this.queryNumber}} <span class="text-sm opacity-80">{{customSizeOfRoutes(this.queryNumber)}}</span>)</span>
            </div>
        </div>
        <Transition name="fade">
            <div class="flex justify-between md:hidden items-center mb-4">
                <router-link to="/dashboard/orders" class="inline-block md:hidden text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>
                <div v-if="!loading">
                    <span class="text-gray-500 hover:text-gray-400 select-none transition-all duration-300 font-semibold text-md">({{this.queryNumber}} <span class="text-sm opacity-80">{{customSizeOfRoutes(this.queryNumber)}}</span>)</span>
                </div>
            </div>
        </Transition>



        <!-- SEARCHER -->
        <div class="min-w-0 flex-1 xl:col-span-6 mt-10">
            <div :class="['w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between']">
                <Transition name="fade">
                <div v-if="!this.showStatusFilters" class="flex flex-col lg:flex-row gap-2 mb-2">
                    <label for="search" class="sr-only">Szukaj</label>
                    <div class="relative">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon class="mb-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                    </div>
                    <button type="button" :disabled="this.searcher.locked" @click.prevent="search" :class="[this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : '', 'transition-all duration-300 rounded-md bg-indigo-100 px-5 py-3 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                        Szukaj
                    </button>
                </div>
                </Transition>

                <Transition name="fade">
                <div v-if="showStatusFilters === true" class="flex flex-col justify-between gap-4 select-none">
                    <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                        <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Status</label>
                                <select v-model="pickedStatus" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                    <option value="all">Wszystkie</option>
                                    <option v-for="status in statuses.filter(el => el.id !== 3)" :value="status.id" :key="status.id">{{status.name}}</option>
                                </select>
                            </div>
                            <div class="flex items-center">
                                <div :class="{'relative w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null}">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data wyjazdu</label>
                                    <input :disabled="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" type="date" @change="pickDateOfDelivery($event)" v-model="pickedDateOfDelivery" ref="pickedDeliveryDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                                </div>
                                <Transition name="fade2">
                                <div v-if="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" class="ml-2 mr-2">
                                    <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                                </div>
                                </Transition>
                            </div>
                            <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                                <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie</label>
                                    <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                        <option value="creationDesc">Utworzono: Od najnowszych</option>
                                        <option value="creationAsc">Utworzono: Od najstarszych</option>
                                        <option value="departureDateAsc">Data wyjazdu: Od najwcześniejszych</option>
                                        <option value="departureDateDesc">Data wyjazdu: Od najpóźniejszych</option>
                                        <option value="returnDateAsc">Data powrotu: Od najwcześniejszych</option>
                                        <option value="returnDateDesc">Data powrotu: Od najpóźniejszych</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                                <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Kraj</label>
                                    <select v-model="pickedCountry" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                        <option value="all">Wszystkie</option>
                                        <option v-for="(country, index) in countries" :key="index" :value="country.name">{{country.displayName}}</option>
                                    </select>
                                </div>
                            </div>
                            <button @click="this.pickStatus()" type="button" class="transition-all duration-300 rounded-md bg-indigo-100 px-3 py-3 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200">
                                Zastosuj
                            </button>
                            <div v-if="showStatusFilters" @click="generateCSVMultiple()" :class="['cursor-pointer flex justify-center items-center flex-col select-none lg:mb-2', blockButton ? 'opacity-50 pointer-events-none' : '']">
                                <DocumentDownloadIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                                <span class="text-xs mt-1 font-medium text-blue-700">{{'Wygeneruj trasy'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </Transition>

                <Transition name="fade">
                <div v-if="searcher.found.length === 0" :class="[showStatusFilters ? 'min-w-filters' : 'flex justify-between gap-3']">
                    <div @click="showFilters()" class="cursor-pointer flex justify-center items-center flex-col select-none lg:mb-2">
                        <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                        <span class="text-xs mt-1 font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                    </div>
                    <div class="flex justify-between gap-2">
                        <router-link v-if="!showStatusFilters" to="/dashboard/routes/archives" :class="[loading ? 'opacity-50 pointer-events-none' : '']">
                            <button :disabled="loading" type="button" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                <ArchiveIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                Archiwum tras
                            </button>
                        </router-link>
                        <router-link v-if="!showStatusFilters" to="/dashboard/routes/create" :class="[loading ? 'opacity-50 pointer-events-none' : '']">
                            <button :disabled="loading" type="button" :class="['transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                                <ViewGridAddIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                Utwórz trasę
                            </button>
                        </router-link>
                    </div>
                </div>
                </Transition>
            </div>
            <Transition name="fade">
            <div v-if="searcher.found.length > 0">
                <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                    Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                    <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>          
            </div>
            </Transition>
        </div>

        <TableRoutesList :routes="searcher.found.length > 0 ? searcher.found : routes" />
        <LoadingSpinnerHub v-if="loading"/>
        <EmptyState class="mt-2 mb-2" v-if="routes.length === 0 && searcher.found.length === 0" />
        <div class="flex items-center justify-center mt-4" v-if="!queryLimitReached">
            <button type="button" @click.prevent="loadMoreRoutes()" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
        </div>
    </div>
</template>
<script>
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import { HomeIcon } from '@heroicons/vue/solid'
import { SearchIcon, ViewGridAddIcon, PlusSmIcon, XIcon , FilterIcon, ArchiveIcon, DocumentDownloadIcon } from '@heroicons/vue/outline';
import EmptyState from '../components/EmptyState.vue'
import TableRoutesList from '../components/TableRoutesList.vue'
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';

export default {
    data() {
        return {
            queryNumber: 0,
            loading: false,
            pickedStatus: "all",
            pickedCountry: 'all',
            sortName: "creationDesc",
            sortType: {
                order: "desc",
                type: "meta.createdDate"
            },
            showStatusFilters: false,
            modal: {
                open: false
            },
            moment: moment,
            routes:[],
            orders: [],
            searcher: {
                found: [],
                locked: false,
                phrase: "",
                lastCall: null
            },
            vehicles: {},
            statuses: [],
            queryLimit: 25,
            dbListener: null,
            query: null,
            lastLoaded: null,
            queryLimitReached: false,
            pickedDateOfDelivery: null,
            pickedDateOfDeliveryUnix: null,
            blockButton: false,
            countries: [
                {
                    displayName: 'Polska',
                    name: 'Poland',
                    code: 'PL',
                    flagUrl: 'https://flagcdn.com/w320/pl.png',
                },
                {
                    displayName: 'Niemcy',
                    name: 'Germany',
                    code: 'DE',
                    flagUrl: 'https://flagcdn.com/w320/de.png',
                },
                {
                    displayName: 'Francja',
                    name: 'France',
                    code: 'FR',
                    flagUrl: 'https://flagcdn.com/w320/fr.png',
                },
                {
                    displayName: 'Luksemburg',
                    name: 'Luxembourg',
                    code: 'LU',
                    flagUrl: 'https://flagcdn.com/w320/lu.png',
                },
                {
                    displayName: 'Włochy',
                    name: 'Italy',
                    code: 'IT',
                    flagUrl: 'https://flagcdn.com/w320/it.png',
                },
                {
                    displayName: 'Holandia',
                    name: 'Netherlands',
                    code: 'NL',
                    flagUrl: 'https://flagcdn.com/w320/nl.png',
                },
                {
                    displayName: 'Belgia',
                    name: 'Belgium',
                    code: 'BE',
                    flagUrl: 'https://flagcdn.com/w320/be.png',
                },
                {
                    displayName: 'Austria',
                    name: 'Austria',
                    code: 'AT',
                    flagUrl: 'https://flagcdn.com/w320/at.png',
                },
            ],
            orderStatuses: []
        }
    },
    components: {
        DocumentDownloadIcon, ViewGridAddIcon, EmptyState, TableRoutesList, LoadingSpinnerHub, HomeIcon, SearchIcon, PlusSmIcon, XIcon, FilterIcon, ArchiveIcon
    },
    beforeUnmount() {
        if(this.dbListener !== null){
            this.dbListener();
        }
    },
    async created()
    {
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.routes)
        {
            this.$router.push("/hub")
        }
        else
        {
            await this.getVehicles()
            await this.getStatuses()
            await this.getOrderStatuses();
            await this.getRoutes();
        }
    },
    methods: {
        
        async generateCSVMultiple()
        {
            try {
                this.blockButton = true;
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Trwa przygotowywanie pliku...",
                    subheader: `Nie zamykaj tego okna.`,
                });
                if(this.orderStatuses.length === 0){
                    const statuses = await db.collection("OrderStatuses").get()
                    statuses.forEach(statusesList => {
                        this.orderStatuses = statusesList.data().list
                    })
                }
                const csvForRoute = [];
                let breakLine = {}
                
                for(let i=0; i<this.routes.length; i++)
                {
                    this.$store.commit('setGlobalLoader',{
                        show: true,
                        head: "Trwa generowanie pliku dla tras: " + (i+1) + "/" + this.routes.length,
                        subheader: `Nie zamykaj tego okna.`,
                    });
                    const route = this.routes[i];
                    let routeHeaderLine = {
                        routeId: 'ID trasy:',
                        routeName: 'Nazwa trasy:',
                        routeDesc: 'Opis trasy:',
                        break1: ' ',
                        routeDrivers: 'Kierowcy:',
                        routeVehicle: 'Pojazd:',
                        break2: ' ',
                        routeDate: 'Data trasy:',
                        break3: ' ',
                        routeValue: 'Wartość trasy:',
                        break4: ' '
                    };
                    let routeDataLine = {
                        routeId: `${route.id !== undefined && route.id.length > 0 ? route.id : '???'}`,
                        routeName: `${route.title !== undefined ? route.title : route.vehicle.brand + ' ' + route.vehicle.model}`,
                        routeDesc: `${route.description !== undefined && route.description.length > 0 ? route.description : '--'}`,
                        break1: ' ',
                        routeDrivers: `${route.drivers.length === 0 ? '--' : ''}`,
                        routeVehicle: `${route.vehicle.brand !== undefined && route.vehicle.brand.length > 0 ? route.vehicle.brand : '???'} ${route.vehicle.model !== undefined && route.vehicle.model.length > 0 ? route.vehicle.model : '???'} (rej. ${route.vehicle.attribs.licensePlate.value !== undefined && route.vehicle.attribs.licensePlate.value.length > 0 ? route.vehicle.attribs.licensePlate.value : '???'})`,
                        break2: ' ',
                        routeDate: `${moment.unix(route.date.start.seconds || route.date.start._seconds).format("LL")} - ${moment.unix(route.date.end.seconds || route.date.end._seconds).format("LL")}`,
                        break3: ' ',
                        // routeValue: `${route.value !== undefined ? (route.value.totalRouteValue !== undefined ? route.value.totalRouteValue.toFixed(2).toString() : '???') + ' ' + (route.value.currency !== undefined && route.value.currency.length > 0 ? route.value.currency : '???') : '???'}`
                        routeValue: `${this.getValues(route)}, Kwota dostawy: ${this.createDeliveryPrice(route)}`,
                        break4: ' ',
                    }
                    for (let d = 0; d < route.drivers.length; d++) {
                        let crntDriver = route.drivers[d]
                        routeDataLine.routeDrivers += `${d > 0 ? ', ' : ''}${crntDriver.name !== undefined && crntDriver.name.length > 0 ? crntDriver.name : '???'} ${crntDriver.surname !== undefined && crntDriver.surname.length > 0 ? crntDriver.surname : '???'} (tel: ${crntDriver.contact.phone !== undefined && crntDriver.contact.phone.length > 0 ? crntDriver.contact.phone : '???'})`
                    }
                    csvForRoute.push(routeHeaderLine)
                    csvForRoute.push(routeDataLine)
                    csvForRoute.push(breakLine)
                    csvForRoute.push(breakLine)
        
                    let routeOrdersHeaderLine = {
                        index: 'L.p.:',
                        address: 'Adres:',
                        products: 'Produkty:',
                        manufacturer: 'Producenci:',
                        client: 'Klient:',
                        internalOrderId: 'Wewnętrzne ID:',
                        externalOrderId: 'Zewnętrzne ID:',
                        confirmedDeliveryDate: 'Data oznaczenia jako dostarczone:',
                        plannedDeliveryDate: 'Planowana data dostawy:',
                        plannedDeliveryHours: 'Planowana godzina dostawy:',
                        actualStatus: 'Aktualny status zamówienia:',
                        orderValue: 'Wartość zamówienia:',
                        noteInsideOrder: 'Komentarz wewnętrzny:'
                    };
                    csvForRoute.push(routeOrdersHeaderLine)
                    
                    const orderPromises = route.orders.map(order => order.ref.get())
                    const orderDocs = await Promise.all(orderPromises);
        
                    for (let o = 0; o < orderDocs.length; o++) {
                        let crntOrderDoc = orderDocs[o];
                        let crntOrder = crntOrderDoc.data()
                        let orderLine = {
                            index: `${o+1}`,
                            address: `${crntOrder.delivery.country.code}, ${crntOrder.delivery.postCode}, ${crntOrder.delivery.city}, ${crntOrder.delivery.address}`,
                            products: `${await this.createProductsLine(crntOrder)}`,
                            manufacturer: `${this.getManufacturer(crntOrder).toString().replaceAll(',',' | ')}`,
                            client: `${crntOrder.client.name} (tel. ${crntOrder.client.phone.full !== undefined && crntOrder.client.phone.full.length > 0 ? crntOrder.client.phone.full : '???'})`,
                            internalOrderId: `${crntOrder.id}`,
                            externalOrderId: `${crntOrder.originName === 'Manual' ? (crntOrder.meta.external != null && crntOrder.meta.external.ids != null) ? crntOrder.meta.external.ids.externalOrderId : '---' : crntOrder.origin !== undefined ? crntOrder.origin.meta !== undefined ? crntOrder.origin.meta.external !== undefined ? crntOrder.origin.meta.external.ids !== undefined ? crntOrder.origin.meta.external.ids.externalOrderId !== undefined && crntOrder.origin.meta.external.ids.externalOrderId.length > 0 ? crntOrder.origin.meta.external.ids.externalOrderId : crntOrder.origin.meta.external.ids.bolOrderId !== undefined && crntOrder.origin.meta.external.ids.bolOrderId.length > 0 ? crntOrder.origin.meta.external.ids.bolOrderId : crntOrder.origin.meta.external.ids.hoodOrderId !== undefined && crntOrder.origin.meta.external.ids.hoodOrderId.length > 0 ? crntOrder.origin.meta.external.ids.hoodOrderId : crntOrder.origin.meta.external.ids.apiloOrderId !== undefined && crntOrder.origin.meta.external.ids.apiloOrderId.length > 0 ? crntOrder.origin.meta.external.ids.apiloOrderId : crntOrder.origin.meta.external.ids.ottoOrderId !== undefined && crntOrder.origin.meta.external.ids.ottoOrderId.length > 0 ? crntOrder.origin.meta.external.ids.ottoOrderId : "--"  : "--" : "--" : "--" : "--"} ${crntOrder.origin !== undefined ? crntOrder.origin.meta !== undefined ? crntOrder.origin.meta.external !== undefined ? crntOrder.origin.meta.external.source !== undefined ? crntOrder.origin.meta.external.source.name !== undefined && crntOrder.origin.meta.external.source.name.length > 0 ? "(" + crntOrder.origin.meta.external.source.name + ")" : "(" + crntOrder.originName + ")" : "(" + crntOrder.originName + ")" : "(" + crntOrder.originName + ")" : "(" + crntOrder.originName + ")" : "(" + crntOrder.originName + ")"}`,
                            confirmedDeliveryDate: `${crntOrder.delivery.date.confirmed === null ? '--' : crntOrder.delivery.date.confirmed.asText !== undefined ? crntOrder.delivery.date.confirmed.asText : '--'}`,
                            plannedDeliveryDate: crntOrder.delivery.date.planned != null && crntOrder.delivery.date.planned.unix != null ? (moment.unix(crntOrder.delivery.date.planned.unix).format("DD.MM.YYYY")) : 'Brak ustalonej daty',
                            plannedDeliveryHours: crntOrder.delivery.date.planned != null && crntOrder.delivery.date.planned.toHourUnix != null && crntOrder.delivery.date.planned.fromHourUnix != null ? (moment.unix(crntOrder.delivery.date.planned.fromHourUnix).format('HH:mm') + ' - ' + moment.unix(crntOrder.delivery.date.planned.toHourUnix).format('HH:mm')) : '---',
                            actualStatus: `${this.findOrderStatus(crntOrder.statusId).name} ${crntOrder.recentStatusId !== undefined && (crntOrder.recentStatusId === 400 || crntOrder.recentStatusId === 401) ? "(" + this.findOrderStatus(crntOrder.recentStatusId).name + ")" : "" }`,
                            orderValue: `${this.countOrderValue(crntOrder)}`,
                            noteInsideOrder: `${this.createCommentList(crntOrder)}`
                        }
                        csvForRoute.push(orderLine)
                    }
                    csvForRoute.push(breakLine)
                }
                const csvData = this.csvMaker(csvForRoute);
                this.downloadAsCSV(csvData, 'trasy_'+moment().format('YYYY-MM-DD_HH-mm-ss'));
                this.$store.commit("resetGlobalLoader");
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wygenerowano plik dla załadowanych tras",
                    subheader: `Plik został pobrany na Twój komputer.`,
                    success: true
                });
                this.blockButton = false;
            } catch (error)
            {
                console.log(error);    
                this.blockButton = false;
                this.$store.commit("resetGlobalLoader");
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Wystąpił błąd podczas generowania pliku dla trasy",
                    subheader: `Spróbuj ponownie później.`,
                    success: true
                });
            }
        },
        csvMaker(data)
        {
            let csvRows = [];
            for(let i=0;i<data.length; i++)
            {
                const values = Object.values(data[i]).join(';');
                csvRows.push(values)
            }
            return csvRows.join('\n')
        },
        downloadAsCSV(data, fileName)
        {
            const blob = new Blob(["\ufeff", data], { type: 'text/csv;charset=utf-8'});
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.setAttribute('href', url);
            a.setAttribute('download', `${fileName}`+`.csv`);
            a.click()
        },
        createDeliveryPrice(route)
        {
          let translatedListOfDelivery = [];
            if(route.value !== undefined)
            {
                if(route.value.delivery !== undefined)
                {
                    if(Object.keys(route.value.delivery).length > 0)
                    {
                        const values = Object.entries(route.value.delivery)
                        values.forEach(element => {
                            if(element[0])
                            {
                                translatedListOfDelivery.push(parseFloat(element[1].price).toFixed(2) + ' ' + element[0]);
                            }
                        })
                    }
                    else
                    {
                       translatedListOfDelivery.push('---'); 
                    }
                }
                else
                {
                    translatedListOfDelivery.push('---');
                }
            }
            else
            {
                translatedListOfDelivery.push('---');
            }
            return translatedListOfDelivery
        },
        createCommentList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfComments = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfComments.push(element[1].infos.onOrderCreation);
                }
            })

            return translatedListOfComments
        },
        async createProductsLine(order)
        {
            let products = ""
            let variantName = ''
            for(const pos in order.positions)
            {
                let crntPos = order.positions[pos]
                let variantId = crntPos.product.variantId
                let product = (await crntPos.product.ref.get()).data()
                if(product !== undefined)
                {
                    products = products.concat(`${crntPos.product.quantity}x ${product.name} `)
                    if(product.variants[variantId] != null)
                    {
                        variantName += `(${product.variants[variantId].name}) `
                    }
                    else
                    {
                        variantName = `(${product.name} - USUNIĘTY WARIANT)`
                    }
                }
                else
                {
                    variantName = `${crntPos.product.quantity}x ${crntPos.product.name} `
                }
            }
            let productsString = `${products + variantName}`
            return productsString
        },
        getManufacturer(order)
        {
            const manufacturer = Object.entries(order.positions)
            let translatedListOfManufacturer = [];
            manufacturer.forEach(element => {
                if(element[0])
                {
                    if(!translatedListOfManufacturer.includes(element[1].product.manufacturer))
                    {
                        translatedListOfManufacturer.push(typeof element[1].product.manufacturer === 'object' ? element[1].product.manufacturer.name : element[1].product.manufacturer);
                    }
                }
            })
            return translatedListOfManufacturer
        },
        countOrderValue(order){
            let value = {
                totalOrderValue: 0,
                currency: '',
            };

            for(const pos in order.positions)
            {
                value = {
                    totalOrderValue: value.totalOrderValue + (order.positions[pos].product.quantity * order.positions[pos].product.pricePerItemTaxIncluded),
                    currency: order.positions[1].product.currency
                }
            }

            let valueString = `${value.totalOrderValue.toFixed(2)} ${value.currency}`
            return valueString
        },
        resetDateOfCreate(){
            this.pickedDateOfDelivery = null;
            this.pickedDateOfDeliveryUnix = null
        },
        pickDateOfDelivery(event){
            let mDate = moment(event.target.value)
            if(!mDate.isValid())
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Popraw datę!",
                    subheader: `Wykryto, że data jest niepoprawna.`,
                    success: false
                });
                this.pickedDateOfDelivery = null;
                this.pickedDateOfDeliveryUnix = null
            }
            else
            {
                this.pickedDateOfDeliveryUnix = mDate.unix()
            }
        },
        customSizeOfRoutes(n)
        {
            let str = '';
            if(n < 1)
            {
                str = "BRAK TRAS"
            }
            else if(n === 1)
            {
                str = 'trasa';
            }
            else if(n > 1 && n < 5)
            {
                str = 'trasy';
            }
            else if(n >= 5)
            {
                str = 'tras';
            }
            return str;
        },
        async getVehicles(){
            let vehicleData = await db.collection('Vehicles').get()
            for (let i = 0; i < vehicleData.docs.length; i++) {
                const vehicle = vehicleData.docs[i];
                const vehicleToPush = vehicle.data()
                this.vehicles[vehicleToPush.id] = vehicleToPush
            }
        },
        createDeliveryPrice(route)
        {
            let translatedListOfDelivery = [];
            if(route.value !== undefined)
            {
                if(route.value.delivery !== undefined)
                {
                    if(Object.keys(route.value.delivery).length > 0)
                    {
                        const values = Object.entries(route.value.delivery)
                        values.forEach(element => {
                            if(element[0])
                            {
                                translatedListOfDelivery.push(parseFloat(element[1].price).toFixed(2) + ' ' + element[0]);
                            }
                        })
                    }
                    else
                    {
                        translatedListOfDelivery.push('---'); 
                    }
                }
                else
                {
                    translatedListOfDelivery.push('---');
                }
            }
            else
            {
                translatedListOfDelivery.push('---');
            }
            return translatedListOfDelivery
        },
        getValues(route)
        {
            let translatedListOfValues = [];
            if(route.value !== undefined)
            {
                if(route.value.total !== undefined)
                {
                    const values = Object.entries(route.value.total)
                    values.forEach(element => {
                        if(element[0])
                        {
                            if(element[1].value !== 0)
                            {
                                translatedListOfValues.push(parseFloat(element[1].value).toFixed(2) + ' ' + element[0]);
                            }
                        }
                    })
                }
                else
                {
                    translatedListOfValues.push('Brak wartości trasy.');
                }
            }
            else
            {
                translatedListOfValues.push('Brak wartości trasy.');
            }
            return translatedListOfValues
        },
        showFilters()
        {
            this.showStatusFilters = !this.showStatusFilters
            if(!this.showStatusFilters)
            {
                this.pickedStatus = 'all';
                this.pickedCountry = 'all';
                this.sortName = "creationDesc";
                this.sortType = {
                    order: "desc",
                    type: "meta.createdDate"
                },
                this.pickedDateOfDelivery = null;
                this.pickedDateOfDeliveryUnix = null;
                this.pickStatus()
            }
        },
        pickStatus(){
            this.dbListener()
            this.getRoutes()
        },
        findOrderStatus(statusId)
        {
            return this.orderStatuses.find(el => el.id === statusId)
        },
        changeSort()
        {
            if(this.sortName === "creationAsc"){
                this.sortType = {
                    order: "asc",
                    type: "meta.createdDate"
                }
            }
            if(this.sortName === "creationDesc"){
                this.sortType = {
                    order: "desc",
                    type: "meta.createdDate"
                }
            }
            if(this.sortName === "returnDateAsc"){
                this.sortType = {
                    order: "asc",
                    type: "date.end"
                }
            }
            if(this.sortName === "returnDateDesc"){
                this.sortType = {
                    order: "desc",
                    type: "date.end"
                }
            }
            if(this.sortName === "departureDateAsc"){
                this.sortType = {
                    order: "asc",
                    type: "date.start"
                }
            }
            if(this.sortName === "departureDateDesc"){
                this.sortType = {
                    order: "desc",
                    type: "date.start"
                }
            }
        },
        async getRoutes()
        {
            this.query = null;
            if(this.dbListener !== null){
                this.dbListener()
                this.dbListener = null
            }
            this.query = db.collection("Routes")
            switch(this.pickedStatus)
            {
                case 'all':
                    this.query = this.query.where("statusId", "in", [1,2])
                    break;
                case 1:
                    this.query = this.query.where('statusId', "==", 1)
                    break;
                case 2:
                    this.query = this.query.where('statusId', "==", 2)
                    break;
                default:
                    this.query = this.query      
                    break;
            }

            if(this.pickedCountry !== 'all')
            {
                this.query = this.query.where('country.name', "==", this.pickedCountry)
            }

            if(this.pickedDateOfDeliveryUnix !== null){
                this.pickedDateOfDeliveryUnix = parseInt(this.pickedDateOfDeliveryUnix)
                let startOfDayDelivery = this.pickedDateOfDeliveryUnix
                this.query = this.query.where('date.start', "==", moment.unix(startOfDayDelivery).toDate())
            }

            this.query = this.query.orderBy(`${this.sortType.type}`, `${this.sortType.order}`)

            const getOrdersQueryNumber = await this.query.get()
            if(!getOrdersQueryNumber.empty)
            {
                this.queryNumber = getOrdersQueryNumber.docs.length;
            }

            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.routes = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                for (let i = 0; i < querySnapshot.docs.length; i++) {
                    const data = querySnapshot.docs[i].data()
                    data.vehicle = this.vehicles[data.vehicle.id]
                    this.routes.push(data)
                }
            });
        },
        resetSearch()
        {  
            this.products = [];
            this.searcher.found = [];
            this.searcher.lastCall = null;
            this.searcher.phrase = "";
            this.getRoutes()
        },
        async search()
        {
            if(this.searcher.phrase.length > 0)
            {
                this.searcher.locked = true;
                const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                    index: "jan-art-routes",
                    collection: "Routes",
                    phrase: this.searcher.phrase,
                    searchFields: ['names', 'countries', 'brands', 'models', 'licensePlates', 'id']
                })
                if(res.data.success)
                {
                    let allFound = []
                    res.data.found.forEach((found) => {
                        let current = found
                        current.vehicle = this.vehicles[current.vehicle._path.segments[1]]
                        allFound.push(current);
                    });
                    this.searcher.found = allFound;
                    if(res.data.found.length > 0)
                    {
                        this.searcher.lastCall = res.data.data;
                        this.dbListener();
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Wyszukiwanie zakończone!",
                            subheader: `Znaleziono ${res.data.found.length} zamówień.`,
                            success: true
                        });

                    }else{
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Nie znaleziono wyników!",
                            subheader: `Spróbuj wyszukać inną frazę.`,
                            success: false
                        });
                    }
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Wyszukiwanie zakończone niepowodzeniem!",
                        subheader: `Coś poszło nie tak...`,
                        success: false
                    });
                }
                setTimeout(()=>
                {
                    this.searcher.locked = false;
                }, 3000)
            }
            else
            {
                return;
            }
        },
        continueListening()
        {
            this.queryLimit = this.queryLimit + 20;
            this.getRoutes()
        },
        loadMoreRoutes()
        {
            this.dbListener();
            this.continueListening();      
        },
        copyToClipboard(id)
        {
            navigator.clipboard.writeText(id).then(()=>
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Skopiowano do schowka!",
                    subheader: ``,
                    success: true
                }); 
            })
        },
        getStatusById(id)
        {
            let currentStatus = {
                name: "Brak",
                info: "Brak",
                id: null
            }
            this.statuses.forEach((status) => {
                if(status.id === id){
                    currentStatus = {
                        name: status.name,
                        info: status.info,
                        id: status.id
                    }
                }
            })
            return currentStatus
        },
        async getStatuses()
        {
            try {
                let res = await db.collection("RouteStatuses").doc("StatusList").get()
                this.statuses = res.data().list
            } catch (error)
            {
                console.log(error);    
            }
        },
        async getOrderStatuses()
        {
            try {
                const statuses = await db.collection("OrderStatuses").get()
                statuses.forEach(statusesList => {
                    this.orderStatuses = statusesList.data().list
                })
            } catch (error)
            {
                console.log(error);   
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    .fade-enter-active{
    transition: opacity 1s ease;
    }
    .fade-leave-active{
        transition: opacity 0s;
        display: none;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }

    .fade2-enter-active,
    .fade2-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade2-enter-from,
    .fade2-leave-to {
    opacity: 0;
    }
</style>